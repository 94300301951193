<template>
  <div class="d-flex justify-end" id="app-bar">
    <logo-square-no-text class="pl-2" :size="39" :color="'white'"/>
    <div class="mr-auto">
      <v-app-bar-nav-icon color="white" @click.stop="switchNav" class="d-block d-sm-none"></v-app-bar-nav-icon>
    </div>
    <!--v-btn class="d-none d-sm-block" text color="white">Nos projets</v-btn-->
    <v-btn light class="mt-auto mb-auto" rounded @click="redirectTo(loginUrl)">Accès client</v-btn>
  </div>
</template>
<script>
import LogoSquareNoText from '@/svg/LogoSquareNoText.vue'

    export default {
    name: "app-bar",
    components: {
      LogoSquareNoText
    },
    props: {
        loginUrl: String,
    },
    data() {
      return {
      }
    },
    methods: {
        switchNav: function (){
            this.$root.$data.drawer = !this.$root.$data.drawer;
        },
        redirectTo: function(url) {
            document.location.href = url;
        }
    },
    destroyed() {
    },
    mounted() {
    }
  };
</script>

<style lang="scss">
  #app-bar {
    width: 100%;
  }
  button {
    margin-right: 10px;
  }
  button:last-of-type {
    margin: 0px;
  }
</style>