<template>
<svg
  :width='size'
   version="1.1"
   id="svg154"
   xml:space="preserve"
   viewBox="0 0 1737.3333 1737.3333"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><defs
     id="defs158"><clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath168"><path
         d="M 0,1303 H 1303 V 0 H 0 Z"
         id="path166" /></clipPath><clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath188"><path
         d="M 0,1303 H 1303 V 0 H 0 Z"
         id="path186" /></clipPath><clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath200"><path
         d="M 0,1303 H 1303 V 0 H 0 Z"
         id="path198" /></clipPath></defs><g
     id="g160"
     transform="matrix(1.3333333,0,0,-1.3333333,0,1737.3333)"><g
       id="g162"><g
         id="g164"
         clip-path="url(#clipPath168)"><g
           id="g170"
           transform="translate(132.7819,217.5088)"><path
             d="m 0,0 v 2.934 h -16.082 l 0.038,-0.045 c -18.38,-0.08 -36.76,-0.067 -55.14,-0.054 l 0.003,0.002 h -3.147 c -4.156,0.002 -8.313,0.006 -12.469,0.006 -0.576,-10e-4 -1.091,-0.002 -1.579,-0.006 h -5.756 v -17.14 l 0.059,-17.622 0.002,0.007 c -0.002,-42.114 -0.003,-84.228 0.002,-126.343 0.001,-1.253 -0.148,-2.573 0.025,-3.782 v -3.488 h 14.789 l 0.173,0.006 c 0.735,0.003 1.459,0.003 2.203,0.005 1.636,0.006 3.294,0.013 4.982,0.013 v 6.418 52.866 c 0,0.363 0,0.71 0,1.054 0,0.147 0.001,0.288 0.001,0.432 0,0.218 0,0.431 0.001,0.642 0,0.152 10e-4,0.302 0.002,0.449 0.001,0.228 0.003,0.447 0.005,0.665 0.001,0.11 0.001,0.224 0.003,0.332 0.107,9.733 1.19,10.198 11.998,10.22 0.267,0 0.538,0.001 0.815,0.001 h 0.872 c 15.621,10e-4 31.243,-0.009 46.864,0.017 l -0.008,-0.003 h 3.801 c 0.657,-0.018 1.338,-0.058 1.965,0 h 2.435 v 3.056 c 0,0.008 0.002,0.015 10e-4,0.025 -0.005,0.609 -0.01,1.219 -0.014,1.829 -0.003,0.61 -0.006,1.222 -0.007,1.835 -0.002,0.614 -0.003,1.23 -0.003,1.849 -10e-4,1.236 10e-4,2.486 0.004,3.756 10e-4,0.634 0.003,1.275 0.004,1.92 0.003,1.292 0.005,2.61 0.005,3.958 h -6.372 c -19.03,0.001 -38.061,0.119 -57.09,-0.088 -0.497,-0.006 -0.95,0.008 -1.366,0.044 -3.111,0.258 -4.046,1.704 -3.999,5.355 0.208,16.033 0.245,32.073 -0.017,48.105 -0.005,0.331 0.001,0.636 0.015,0.93 0.141,3.112 1.353,4.203 3.909,4.478 h 10e-4 c 0.265,0.028 0.547,0.048 0.84,0.06 0.04,0.002 0.079,0.004 0.12,0.005 0.301,0.012 0.615,0.017 0.948,0.014 14.933,-0.137 29.866,-0.113 44.8,-0.094 4.978,0.007 9.956,0.013 14.933,0.013 H 0 c 0,0.137 -0.003,0.268 -0.003,0.404 L 0,-14.963 v 11.068 c 0.024,0.326 0.046,0.652 0.081,0.976 C 0.207,-1.729 0.178,-0.771 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path172" /></g><g
           id="g174"
           transform="translate(199.714,50.71)"><path
             d="m 0,0 c 2.719,0.131 4.877,0.619 5.932,1.008 1.07,0.318 2.14,0.729 3.106,1.27 L 8.083,8.976 C 7.879,10.82 7.538,13.05 7.12,15.741 l -0.295,2.064 c 0,0 -1.744,-0.257 -4.08,-0.579 -14.14,-2.292 -19.768,2.442 -19.885,16.768 -0.01,1.235 -0.005,2.469 -0.005,3.702 v 134.857 6.647 h -19.872 -1.354 -0.397 v -2.458 c -0.01,-49.44 -0.259,-98.882 0.132,-148.318 0.144,-18.256 10.134,-29.09 28.137,-28.663 0.572,0.013 1.122,0.024 1.662,0.035 0.142,0.003 0.283,0.006 0.423,0.009 0.495,0.009 0.982,0.018 1.451,0.026 0.011,0 0.023,0 0.034,0.001 0.602,0.01 1.179,0.02 1.738,0.029 0.421,0.007 0.834,0.014 1.231,0.022 0.049,0.001 0.101,0.002 0.149,0.003 0.421,0.008 0.825,0.016 1.22,0.026 0.098,0.002 0.196,0.005 0.294,0.007 0.116,0.003 0.237,0.006 0.351,0.009 0.041,0.002 0.077,0.004 0.118,0.005 0.331,0.009 0.657,0.02 0.968,0.032 v -0.002 c 0,0 0.325,0.005 0.86,0.037"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path176" /></g><g
           id="g178"
           transform="translate(324.0543,172.9619)"><path
             d="m 0,0 c -2.522,0 -4.905,-0.042 -7.205,-0.07 l -15.07,-0.026 -1.226,-3.888 c -0.093,-0.252 -0.185,-0.513 -0.271,-0.794 -8.292,-26.86 -16.817,-53.648 -25.291,-80.451 -1.415,-4.476 -2.935,-8.92 -4.406,-13.379 -2.971,5.383 -4.822,10.8 -6.567,16.251 -7.907,24.689 -15.745,49.401 -23.769,74.052 v 0 l -2.587,8.209 -11.749,0.02 c -3.384,0.04 -6.87,0.058 -10.497,0.058 0.742,-2.378 1.235,-4.201 1.876,-5.969 10.856,-29.935 21.7,-59.874 32.613,-89.787 l 9.763,-26.664 -1.83,-5.549 -0.003,0.007 c -0.018,-0.051 -0.03,-0.1 -0.049,-0.151 -5.345,-14.479 -10.358,-29.08 -15.919,-44.831 0.208,0 0.41,0.008 0.616,0.01 l -0.02,-0.009 20.932,0.246 3.703,10.755 C -38.59,-109.681 -20.093,-57.446 -1.634,-5.199 -1.091,-3.66 -0.652,-2.084 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path180" /></g></g></g><g
       id="g182"><g
         id="g184"
         clip-path="url(#clipPath188)"><g
           id="g190"
           transform="translate(436.4679,64.0742)"><path
             d="M 0,0 C 0.128,1.209 0.098,2.187 -0.089,2.972 V 5.877 H -4.548 C -4.992,5.906 -5.463,5.919 -5.97,5.915 -26.055,5.725 -46.143,5.824 -66.229,5.848 c -2.039,0.002 -4.077,0.196 -7.473,0.371 2.342,2.224 3.723,3.47 5.03,4.789 14.376,14.511 29.192,28.618 42.98,43.667 13.618,14.863 22.579,32.155 22.91,53.075 0.254,16.016 -4.513,29.553 -16.909,40.326 -13.391,11.64 -36.726,12.976 -54.609,8.664 l 0.004,0.007 c -0.101,-0.025 -0.196,-0.05 -0.297,-0.074 -0.134,-0.033 -0.266,-0.068 -0.399,-0.101 -15.32,-3.85 -17.688,-6.3 -17.688,-6.3 l 4.896,-16.159 c 8.863,2.342 16.858,5.232 25.1,6.454 12.899,1.914 24.753,-0.781 32.604,-12.442 5.732,-8.515 5.945,-18.251 4.648,-28.021 C -27.286,86.135 -34.283,74.395 -43.305,64.195 -55.571,50.329 -68.197,36.78 -80.864,23.27 l -21.981,-23.293 2.07,-5.23 c 0.455,-1.845 1.318,-3.948 2.599,-6.567 h 98.095 c 0,0.353 -0.004,0.702 -0.008,1.051 v 9.693 C -0.063,-0.717 -0.038,-0.357 0,0"
             style="fill:#bd4d3b;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path192" /></g></g></g><g
       id="g194"><g
         id="g196"
         clip-path="url(#clipPath200)"><g
           id="g202"
           transform="translate(1182.6862,72.3057)"><path
             d="m 0,0 c -0.835,-3.7 -2.074,-8.264 -2.76,-12.909 -0.169,-1.141 1.21,-3.355 2.319,-3.713 19.997,-6.481 40.122,-9.316 60.261,-0.623 15.722,6.785 23.754,21.079 20.941,37.356 -1.733,10.02 -8.671,16.565 -16.767,21.376 -8.735,5.19 -18.291,8.981 -27.343,13.664 -4.652,2.409 -9.547,4.822 -13.341,8.31 -6.824,6.274 -4.462,17.295 4.409,19.957 6.076,1.823 12.824,2.829 19.092,2.25 8.459,-0.783 16.745,-3.439 25.342,-5.345 -0.048,-0.087 0.312,0.342 0.431,0.83 0.83,3.409 1.407,6.895 2.467,10.228 0.99,3.119 -0.248,4.468 -2.976,5.564 -17.249,6.933 -34.8,8.474 -52.569,2.506 C 5.574,94.772 -2.063,82.848 -1.441,68.179 -0.885,55.083 7.446,47.391 17.656,41.582 25.571,37.079 34.343,34.107 42.489,29.975 47.291,27.538 52.278,24.821 56.018,21.07 63.299,13.769 61.278,2.139 51.766,-1.675 45.444,-4.208 38.088,-5.712 31.347,-5.284 20.975,-4.627 10.732,-1.917 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path204" /></g><g
           id="g206"
           transform="translate(927.858,70.4111)"><path
             d="m 0,0 c -11.943,-1.117 -23.037,-2.969 -34.131,-2.969 -11.692,0 -21.547,5.245 -27.62,15.694 -9.655,16.61 -10.048,34.086 -3.538,51.826 4.225,11.511 12.141,19.652 24.478,21.587 7.855,1.231 16.053,1.025 24.029,0.478 5.466,-0.375 10.815,-2.445 16.534,-3.835 0.748,3.916 1.16,7.59 2.194,11.079 1.099,3.709 -0.128,5.409 -3.622,6.63 -11.507,4.022 -23.37,4.825 -35.381,4.195 -14.045,-0.739 -26.405,-5.722 -36.485,-15.675 -18.516,-18.283 -23.301,-52.939 -10.937,-78.33 6.916,-14.201 17.828,-23.996 32.97,-28.461 17.442,-5.144 34.769,-3.562 51.723,2.571 1.009,0.364 2.14,2.447 1.996,3.576 C 1.663,-7.319 0.617,-3.068 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path208" /></g><g
           id="g210"
           transform="translate(557.7467,119.3477)"><path
             d="m 0,0 c -7.314,10.383 -17.4,17.272 -28.42,23.057 -10.902,5.722 -22.027,11.072 -32.607,17.339 -10.891,6.453 -14.495,16.474 -11.513,28.57 2.473,10.029 11.965,16.3 25.232,16.986 15.273,0.79 29.662,-2.577 44.243,-9.38 0.627,2.565 1.17,4.96 1.723,7.264 l 2.506,9.552 c 0,0 -0.587,0.704 -2.947,1.895 -0.307,0.168 -0.643,0.33 -1.007,0.488 -1.473,0.68 -3.463,1.484 -6.142,2.382 -0.066,0.022 -0.117,0.032 -0.18,0.052 -16.43,5.757 -33.365,7.119 -50.638,3.719 -15.965,-3.144 -28.381,-11.491 -33.589,-27.799 -6.364,-19.924 0.764,-38.545 19.848,-50.195 10.627,-6.489 22.206,-11.399 33.119,-17.446 6.107,-3.383 12.444,-6.888 17.438,-11.641 15.189,-14.454 10.158,-36.984 -9.548,-44.067 -15.228,-5.474 -30.518,-3.339 -45.659,0.61 -5.507,1.436 -10.848,3.51 -16.586,5.398 -0.164,-0.678 -0.315,-1.341 -0.461,-1.997 -0.055,-0.243 -0.107,-0.479 -0.159,-0.72 -0.111,-0.506 -0.221,-1.006 -0.328,-1.501 -0.115,-0.529 -0.228,-1.045 -0.343,-1.562 -0.03,-0.138 -0.061,-0.277 -0.091,-0.414 l -0.797,-3.257 c -0.263,-0.961 -0.552,-1.902 -0.887,-2.828 -0.432,-1.197 -0.609,-2.171 -0.538,-2.989 l -0.378,-1.548 c 0,0 2.328,-2.174 11.761,-4.775 0.216,-0.059 0.402,-0.096 0.59,-0.135 10.04,-3.01 20.339,-4.578 30.923,-4.963 15.649,-0.57 30.346,2.09 43.741,10.783 C 8.578,-45.964 13.851,-19.664 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path212" /></g><g
           id="g214"
           transform="translate(695.1334,166.4316)"><path
             d="m 0,0 0.039,6.092 h -21.96 v -20.84 c -0.05,-6.153 -0.128,-12.309 -0.174,-18.461 l -0.001,-0.002 v -0.14 c -0.038,-5.106 -0.049,-10.211 0,-15.313 v -29.128 c -1.928,-8.304 -7.779,-13.314 -15.445,-17.839 -1.351,-0.797 -2.856,-1.37 -4.345,-1.89 -17.005,-5.932 -31.984,2.124 -35.843,19.629 -1.315,5.966 -2.1,12.163 -2.185,18.267 -0.242,17.493 -0.156,34.992 -0.149,52.489 l 0.004,0.006 V 6.092 h -0.26 c -10e-4,0.011 -10e-4,0.022 -0.002,0.033 h -21.12 c -10e-4,-0.012 -0.002,-0.021 -0.003,-0.033 h -0.391 v -39.303 l 0.005,0.006 c 0,-9.376 0.079,-18.751 0.38,-28.117 0.297,-9.195 1.803,-18.636 4.534,-27.412 8.533,-27.413 38.919,-36.971 62.191,-19.982 4.459,3.256 8.47,7.127 13.689,11.567 v -17.308 H 0.039 V -0.14 L 0.013,-0.285 C 0.009,-0.189 0.005,-0.094 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path216" /></g><g
           id="g218"
           transform="translate(815.3102,67.4648)"><path
             d="m 0,0 -0.007,0.031 c -0.225,1.049 -0.439,2.035 -0.617,2.915 -20.706,-4.807 -36.337,-7.787 -50.918,1.466 -0.11,0.069 -0.205,0.14 -0.312,0.209 -4.17,2.598 -7.745,6.274 -10.521,11.05 -9.654,16.61 -10.048,34.086 -3.537,51.826 4.224,11.511 12.141,19.651 24.477,21.587 7.856,1.231 16.053,1.025 24.03,0.478 4.63,-0.318 9.178,-1.849 13.937,-3.156 l 0.006,-0.004 0.132,-0.034 c 0.813,-0.222 1.631,-0.439 2.458,-0.64 l 3.161,15.584 c -9.665,5.181 -23.093,6.067 -23.093,6.067 l 0.002,-0.003 c -5.579,0.528 -11.212,0.553 -16.878,0.255 -14.046,-0.738 -26.405,-5.722 -36.485,-15.675 -18.517,-18.283 -23.301,-52.938 -10.938,-78.33 6.915,-14.201 17.829,-23.997 32.971,-28.461 10.089,-2.976 20.139,-3.688 30.102,-2.583 l -0.018,-0.015 c 0,0 12.285,1.074 24.337,6.198 L 0.912,-4.478 C 0.617,-2.9 0.299,-1.391 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path220" /></g><g
           id="g222"
           transform="translate(928.3551,67.4648)"><path
             d="M 0,0 -0.006,0.031 H -0.007 C -0.232,1.08 -0.446,2.066 -0.623,2.946 -21.33,-1.861 -36.96,-4.841 -51.542,4.412 c -0.109,0.069 -0.204,0.14 -0.312,0.209 -4.17,2.598 -7.745,6.274 -10.521,11.05 -9.654,16.61 -10.047,34.086 -3.537,51.826 4.224,11.511 12.141,19.651 24.478,21.587 7.855,1.231 16.053,1.025 24.029,0.478 4.631,-0.318 9.179,-1.849 13.938,-3.156 l 0.005,-0.004 0.132,-0.034 c 0.813,-0.222 1.632,-0.439 2.459,-0.64 l 3.161,15.584 c -9.665,5.181 -23.093,6.067 -23.093,6.067 l 0.001,-0.003 c -5.579,0.528 -11.212,0.553 -16.878,0.255 -14.045,-0.738 -26.405,-5.722 -36.485,-15.675 -18.516,-18.283 -23.301,-52.938 -10.937,-78.33 6.915,-14.201 17.828,-23.997 32.97,-28.461 10.089,-2.976 20.14,-3.688 30.102,-2.583 l -0.018,-0.015 c 0,0 12.285,1.074 24.338,6.198 L 0.913,-4.478 C 0.618,-2.9 0.3,-1.391 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path224" /></g><g
           id="g226"
           transform="translate(1030.2623,123.583)"><path
             d="m 0,0 h -57.61 c -0.351,18.717 11.101,32.896 28.321,35.595 C -14.313,37.94 -1.54,28.136 -0.062,12.922 0.343,8.768 0,4.539 0,0 m 20.613,7.447 c -0.009,0.714 -0.03,1.427 -0.064,2.138 -0.008,0.143 -0.017,0.285 -0.025,0.427 -0.043,0.74 -0.095,1.478 -0.172,2.215 -1.451,13.878 -7.506,25.657 -20.11,32.948 -24.062,13.922 -55.499,6.302 -70.017,-17.432 -12.976,-21.21 -14.001,-43.997 -5.77,-67.025 6.252,-17.492 19.131,-28.302 37.255,-32.661 12.196,-2.934 24.474,-2.543 36.649,-0.135 7.095,1.403 14.027,3.637 21.138,5.525 -1.199,6.171 -2.146,11.05 -3.177,16.349 -14.128,-5.317 -27.831,-7.358 -42.132,-6.287 -19.733,1.476 -34.39,19.325 -31.4,39.764 h 6.077 c 21.677,0.001 43.354,0.067 65.031,-0.061 0.481,-0.003 0.933,0.012 1.358,0.045 h 3.95 l 0.203,2.93 c 0.141,0.531 0.223,1.146 0.24,1.868 0.036,1.474 0.11,2.952 0.202,4.43 0.047,0.746 0.097,1.492 0.15,2.238 0.015,0.208 0.029,0.416 0.045,0.624 0.04,0.565 0.081,1.131 0.123,1.697 0.003,0.045 0.006,0.091 0.01,0.136 0.007,0.103 0.014,0.208 0.021,0.311 0.051,0.693 0.099,1.385 0.145,2.076 0.009,0.129 0.017,0.257 0.025,0.385 0.047,0.722 0.088,1.443 0.124,2.163 0.008,0.153 0.016,0.306 0.023,0.458 0.034,0.74 0.064,1.48 0.082,2.218 10e-4,0.054 10e-4,0.106 0.003,0.16 0.015,0.696 0.019,1.391 0.015,2.085 -10e-4,0.137 0,0.275 -0.002,0.411"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path228" /></g><g
           id="g230"
           transform="translate(1158.7672,92.1768)"><path
             d="m 0,0 c -1.694,10.26 -8.778,16.846 -17.036,21.774 -8.12,4.846 -17.041,8.332 -25.478,12.673 -4.98,2.563 -10.226,5.015 -14.426,8.601 -7.507,6.412 -5.289,17.832 4.124,20.623 6.079,1.802 12.842,2.693 19.117,2.09 8.413,-0.809 16.649,-3.456 25.32,-5.395 l 0.608,2.462 c 0.396,1.556 0.806,3.145 1.177,4.76 l 1.824,7.382 c -11.722,6.636 -25.527,7.87 -25.527,7.87 l 0.021,-0.021 c -10.354,1.311 -20.811,0.36 -31.266,-3.367 -24.037,-8.568 -27.412,-37.27 -10.34,-51.457 3.366,-2.797 6.928,-5.546 10.813,-7.492 7.854,-3.936 16.111,-7.07 23.968,-11.001 4.346,-2.174 8.81,-4.703 12.186,-8.101 7.459,-7.508 5.448,-19.193 -4.374,-23.053 -6.335,-2.491 -13.689,-3.895 -20.44,-3.469 -10.206,0.643 -20.285,3.307 -31.104,5.233 -0.605,-2.751 -1.401,-5.868 -2.038,-9.11 l -1.394,-6.231 c 6.881,-3.209 16.014,-5.103 21.88,-6.07 1.544,-0.266 3.088,-0.492 4.633,-0.68 0.611,-0.079 1.012,-0.124 1.148,-0.139 0.007,0 0.015,-10e-4 0.022,-0.002 0.016,-0.002 0.034,-0.004 0.034,-0.004 l -10e-4,0.001 c 12.114,-1.317 24.244,-0.099 36.32,5.338 C -4.806,-29.841 2.638,-15.978 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path232" /></g><g
           id="g234"
           transform="translate(1263.8278,92.1768)"><path
             d="m 0,0 c -1.694,10.26 -8.778,16.846 -17.036,21.774 -8.12,4.846 -17.041,8.332 -25.478,12.673 -4.98,2.563 -10.226,5.015 -14.426,8.601 -7.507,6.412 -5.289,17.832 4.124,20.623 6.079,1.802 12.842,2.693 19.117,2.09 8.413,-0.809 16.649,-3.456 25.32,-5.395 l 0.608,2.462 c 0.396,1.556 0.806,3.145 1.177,4.76 l 1.824,7.382 c -11.722,6.636 -25.527,7.87 -25.527,7.87 l 0.021,-0.021 c -10.354,1.311 -20.811,0.36 -31.266,-3.367 -24.037,-8.568 -27.412,-37.27 -10.34,-51.457 3.366,-2.797 6.928,-5.546 10.813,-7.492 7.854,-3.936 16.111,-7.07 23.968,-11.001 4.346,-2.174 8.81,-4.703 12.186,-8.101 7.459,-7.508 5.448,-19.193 -4.374,-23.053 -6.335,-2.491 -13.689,-3.895 -20.44,-3.469 -10.206,0.643 -20.285,3.307 -31.104,5.233 -0.605,-2.751 -1.401,-5.868 -2.038,-9.11 l -1.394,-6.231 c 6.881,-3.209 16.014,-5.103 21.88,-6.07 1.544,-0.266 3.088,-0.492 4.633,-0.68 0.611,-0.079 1.012,-0.124 1.148,-0.139 0.007,0 0.015,-10e-4 0.022,-0.002 0.016,-0.002 0.034,-0.004 0.034,-0.004 l -10e-4,0.001 c 12.114,-1.317 24.244,-0.099 36.32,5.338 C -4.806,-29.841 2.638,-15.978 0,0"
             style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path236" /></g></g></g><g
       id="g238"
       transform="translate(589.8501,1303.0001)"><path
         d="m 0,0 -190,-375.2 75.5,11.8 L 0,-92.8 98.1,-277.501 160.2,-268 Z"
         style="fill:#3b5b94;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path240" /></g><g
       id="g242"
       transform="translate(254.15,642.7998)"><path
         d="m 0,0 109.5,33.4 -95.2,-224.299 730.6,-18.301 -195,373.5 79.8,23.2 273.7,-422.1 -1049.3,-54.1 z"
         style="fill:#3b5b94;fill-opacity:1;fill-rule:evenodd;stroke:none"
         id="path244" /></g><g
       id="g246"
       transform="translate(208.2501,816.7998)"><path
         d="m 0,0 535.2,77.6 -1.6,29.8 19.6,-36.6 -607,-176.2 9.1,17.4 z"
         style="fill:#bd4d3b;fill-opacity:1;fill-rule:evenodd;stroke:none"
         id="path248" /></g></g></svg>

</template>

<script>
    export default {
    	name: "logo-square",
		props: {
    	    size: Number,
			color: String
    	},
	}
</script>


