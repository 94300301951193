<template>
  <div id="home-page">
    <section class="title-header-image d-flex justify-center" v-bind:style='{ "background-image": "linear-gradient(-45deg, rgba(110,28,30,0.8), rgba(22,52,150,0.8)), url(" + require("@/assets/images/eria-drone-21.jpg") + ")" }'>
    </section>
    <section class="title-header-text" style="backdrop-filter: blur(5px); display: flex; align-items: center; justify-content: center;" v-bind:style='{ "padding-top":"65px"}'>
      <v-row class="align-self-center ma-5 ">
        <v-col cols="12" md="8" offset-md="2">
          <h1>Sécurisez vos projets de construction à l'aide d'informations fiables</h1>
        </v-col>
        <v-col cols="12" md="8" offset-md="2">
          <h2>Grâce à une connaissance parfaite de votre actif</h2>
        </v-col>
        <v-col style="display: flex; align-items: center; justify-content: center; height: 50px" class="mt-5 mb-5" cols="12">
          <v-btn href="mailto:contact@fly2success.io" x-large light color="error"  rounded>Demander un devis</v-btn>
        </v-col>
        <v-col cols="12" md="8" offset-md="2">
          <h3 style="font-style: italic;">« parce que le plus gros problème est celui qu’on ne connait pas encore »</h3>
        </v-col>
       
      </v-row>
    </section>
    <section class="value-proposition" >
        <v-row class="align-self-center">
          <v-col cols="12">
            <h2>Fly2Success vous aide à créer, contrôler et partager vos plans et maquettes 3D à partir de relevés de nuages de points<br/>
                <span class="description">Découvrez la plateforme web de vos rêves</span></h2>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" md="7">
            <h3 class="">
              Votre chantier repose sur <span class="red--text">des petits détails</span> qui ont de gros impacts <br/>
              <span class="description">
                Le plus gros problème est celui qu’on ne connait pas encore.
              </span>
            </h3>
          </v-col>
          <v-col cols="12" md="5">
            <v-img src="@/assets/images/screenshots/fly2success-3d-comparison-building.jpg"></v-img>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="7">
            <h3>
              Démarrez vos études avec des données d’entrée fiables en <span class="red--text">moins de 10 jours</span><br/>
              <span class="description">
                Parce que nous n’existerions pas si les murs et les sols de vos bâtiments à rénover étaient droits. 
              </span>
            </h3>
          </v-col>
          <v-col cols="12" md="5" order-md="first">
            <v-img src="@/assets/images/screenshots/fly2success-building-section.jpg"></v-img>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="7">
            <h3>
              Economisez jusqu’à <span class="red--text">5% de votre budget travaux</span> et réduisez de 7% la durée de votre projet<br/>
              <span class="description">
                La méthodologie Fly2Success réduit les risques liés aux aléas de chantier.
              </span>
            </h3>
          </v-col>
          <v-col cols="12" md="5">
            <v-img src="@/assets/images/screenshots/fly2success-planeness-building.jpg"></v-img>
          </v-col>
        </v-row>

        <v-row>
                  <v-col style="display: flex; align-items: center; justify-content: center; height: 50px" class="mt-5 mb-5" cols="12">
          <v-btn href="mailto:contact@fly2success.io" x-large light color="error"  rounded>Demander un devis</v-btn>
        </v-col>
          </v-row>
    </section>

    <section class="red">
      <v-row justify="center">
        <v-col md="11" style="margin-top:50px">
          <h2 class="ma-5" style="text-align: center">Découvrez notre solution de visualisation de nuage de points en ligne</h2>

        </v-col>

      </v-row>      <v-row justify="center">
        <v-col md="11">
          <div  class="ma-5" style="position:relative;padding-bottom:56.25%;">
            <iframe width="100%" height="100%" style="position:absolute;top:0;left:0; margin-left: auto; margin-right: auto" src="https://www.youtube.com/embed/jFGUscc5anQ?controls=0&autoplay=1&mute=1&loop=1&showinfo=0&rel=0&playlist=jFGUscc5anQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </v-col>
      </v-row>

    </section>
    
              <!-- <video autoplay loop muted playsinline style="width:100%;height:80vh;"> 
                source src="/assets/videos/website/plateforme_web.webm" type="video/mp4; codecs=&quot;hvc1&quot;"
                <source src="/assets/videos/website/plateforme_web.webm" type="video/webm">
              </video> -->
    
    <v-btn large aria-label="Contactez-nous" href="mailto:contact@fly2success.io" dark fab style="z-index: 1000;position:fixed; bottom: 15px; left: calc(100vw - 151px); background-color: rgb(53, 126, 170); height: 60px; width: 60px;"><v-icon>mdi-email-fast-outline</v-icon></v-btn>
    <v-overlay :value="overlay"></v-overlay>
    <v-dialog
        v-model="contactDialog"
        
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          test
        </v-card>
      
    </v-dialog>
  </div>
</template>
<script>


    export default {
    name: "home-page",
    components: {
    },
    data() {
      return {
        overlay:false,
        contactDialog: false
      }
    },
    methods: {
    },
    created() {
        this.$vuetify.theme.dark = true;
    }
  };
</script>

<style lang="scss">
  html {
    background-color: #1F2023;
  }

  body {
    overflow: hidden;
  }

  #home-page {
    font-family: Montserrat;
    line-height: 1;
    section.title-header-image {
      height: 50vmax;
      max-height: 80vh;
      background-position: center;
    }
    section.title-header-text {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 50vmax;
      max-height: 80vh;
      background-position: center;
      line-height: 1.2;
      h1 {
         font-weight: 800;
         font-size: 4vmax;
         text-align: center;
      }
      h2 {
        font-weight: 300;
        text-align: center;
        font-size: 2vmax;
      }
      h3 {
        font-weight: 300;
        text-align: center;
        font-size: 1.5vmax;
      }    }

    section.value-proposition {
          background-color: #1F2023;

      line-height: 1.2;
      padding: 20px;
      .v-image {
        border-radius: 30px;
        box-shadow: 5px 5px 5px #191717;
      }
      .row {
        padding: 4vw;
        > * {
          margin-top: auto !important;
          margin-bottom: auto !important;
        }
      }
      span.description {
        font-weight: 300;
        font-size: 0.8em;
        line-height: 1;
      }      
      h2 {
        font-weight: 600;
        font-size: 2.5em;
        text-align: center;

      }
      h3 {
        font-weight: 600;
        font-size: 2em;

      }
    }
  }
</style>