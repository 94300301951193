<template>
<div id="footer">
    <v-row>
        <v-col md="12">
          <div style="display: flex; flex-direction: row">
            <logo-square :size="100"></logo-square>
            <div class="ml-5" style="max-width: 800px">
              <p>Accélerez et sécurisez vos projets de construction à partir de données 3D fiables, grâce à notre technologie d’exploitation du nuage de points.</p>
              <v-btn href="mailto:contact@fly2success.io" light color="error"  rounded>Nous contacter</v-btn>
            </div>
            <div  class="ml-5">
              <h3>Suivez-nous</h3>
              <v-btn icon @click="openLink('https://www.linkedin.com/company/fly2success/')" >
                <v-icon large color="white">mdi-linkedin</v-icon>
              </v-btn>
              <v-btn icon @click="openLink('https://www.instagram.com/fly2success.io')">
                <v-icon large color="white">mdi-instagram</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
    </v-row>
    <v-row>
        <v-col md="12">
          <span class="copyright"> Copyright © {{ new Date().getFullYear() }} Fly2Success All Rights reserved </span>
        </v-col>
    </v-row>
  </div>
</template>
<script>

import LogoSquare from '@/svg/LogoSquare.vue'

    export default {
    name: "website-footer",
    components: {
        LogoSquare
    },
    data() {
      return {
      }
    },
    methods: {
      openLink: function(url) {
        window.open(url, '_blank')
      }
    },
    created() {
    }
  };
</script>

<style lang="scss">

  #footer {
    margin: 4em;

    svg {
      path {
        fill: white !important;
      }
    }

    span.copyright {
      font-family: 'Montserrat';
      font-weight: 100;
      font-size: 14px;
    }
  }
</style>