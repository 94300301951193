import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import LogoSquareNoText from '@/svg/LogoSquareNoText' 

Vue.use(Vuetify)

const opts = {}

export default new Vuetify(opts)

export let vuetify_dark = new Vuetify({
	theme: {
		themes: {
		  light: {
			primary: '#3f51b5',
			secondary: '#b0bec5',
			accent: '#8c9eff',
			error: '#b71c1c',
		  },
		  dark: {
			primary: '#3f51b5',
			secondary: '#b0bec5',
			accent: '#8c9eff',
			error: '#BD4D3B',
			red: '#BD4D3B',
			background: '#1F2023',
		  },
		},
	},
	icons: {
		values: {
			product: { // name of our custom icon
				component: LogoSquareNoText, // our custom component
			},
		},
	},		
})