import { vuetify_dark as vuetify } from '@/plugins/vuetify' // path to vuetify export
import Vue from 'vue/dist/vue.esm'
import Headroom from "headroom.js";



Vue.config.productionTip = false;

// Cach the page correctly to enable the user to go back
import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter)

var Turbolinks = require("turbolinks");
Turbolinks.start();

import HomePage from '@/views/Website/HomePage.vue'
Vue.component(HomePage.name, HomePage);
import Footer from '@/views/Website/Footer.vue'
Vue.component(Footer.name, Footer);
import AppBar from '@/views/Website/AppBar.vue'
Vue.component(AppBar.name, AppBar);


function canUseWebP() {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

import CookieEU from "@/plugins/cookies-eu.js";
Vue.use(CookieEU);

// if (navigator.serviceWorker) {
//   navigator.serviceWorker.register('/service-worker.js', { scope: './' })
//     .then(function(reg) {
//       console.log('[Companion]', 'Service worker registered!');
//       console.log(reg);
//     });
// }

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('vue-mount');

  if (el != undefined) {
    const app = new Vue({
      el,
      vuetify,
      data() {
        return {
          drawer: false
        }
      } 
    })
  }

  var dropdown_buttons = document.querySelectorAll('[data-toggle="dropdown"]');

  // Avoid loading turbolinks on dropdown
  dropdown_buttons.forEach(function(element) {
    element.addEventListener('click', function(event) {
      event.preventDefault();
    });
  });

  var navbar = document.querySelector(".headroom");
  // construct an instance of Headroom, passing the element
  if (navbar) {
    var headroom  = new Headroom(navbar, {
    "offset": 205,
    "tolerance": 5,
    });

    // initialise
    headroom.init();
  }
});