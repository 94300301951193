<template>
	<svg :width="size" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1049.3001 948.90002" xml:space="preserve">
		<g id="Triangle" transform="translate(-69.6)" >
			<polygon v-bind:style='{fill: color ? color : "#3B5B94"}' id="Path-2" class="st2" style="fill-rule:evenodd;clip-rule:evenodd;" points="436.7,363.4 551.2,92.8 649.3,277.5 711.4,268 551.2,0 361.2,375.2 "/>
			<polygon v-bind:style='{fill: color ? color : "#3B5B94"}'  id="Path" class="st2" style="fill-rule:evenodd;clip-rule:evenodd;" points="229.8,851.1 960.4,869.4 765.4,495.9 845.2,472.7 1118.9,894.8 69.6,948.9 215.5,660.2 325,626.8 "/>
		</g>
		<g id="Aile" transform="translate(-69.6)">
			<polygon v-bind:style='{fill: color ? color : "#BD4D3B"}' id="Path-3" class="st3" points="703.2,378.8 722.8,415.4 115.8,591.6 124.9,574.2 169.6,486.2 704.8,408.6 "/>
		</g>
	</svg>
</template>

<script>
    export default {
    	name: "logo-square-no-text",
		props: {
    	    size: Number,
			color: String
    	},
	}
</script>
